var App = (function() {
  //Error handler
  window.onerror = function(message, url, lineNumber, colNumber, error) {
    errorHandler(message, url, lineNumber, colNumber, error);
  };

  /**
   * Catch DOM, its more efficient.
   */
  var $xcsrf = $('meta[name="csrf-token"]');
  var $token = $('meta[name="csrf-token"]').attr("content");

  function errorHandler(message, url, lineNumber, colNumber, error) {
    if (APP_CONFIG.ENV != "production" && APP_CONFIG.DEBUG) {
      console.error(error);
      return false;
    }

    return true;
  }

  /**
   * Init the module
   */
  _ajaxSetup();

  /**
   * Setup ajax calls
   */
  function _ajaxSetup() {
    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": $xcsrf.attr("content")
      }
    });
  }

  /**
   * Make a ajax call
   */
  function makeAjaxCall(url, type, data, func) {
    var config = {
      url: url,
      dataType: "json"
    };
    config.data = data === undefined ? {} : data;

    switch (type) {
      case "patch":
        config.type = "post";
        config.data._method = "patch";
        break;
      case "put":
        config.type = "post";
        config.data._method = "put";
        break;
      case "delete":
        config.type = "post";
        config.data._method = "delete";
        break;
      default:
        config.type = type;
        break;
    }

    $.ajax(config)
      .done(function(response) {
        return func(response);
      })
      .fail(function(response) {
        console.log(response);
      });
  }

  /**
   * Make a Form Data ajax call
   */
  function makeDataAjaxCall(url, type, data, func) {
    var config = {
      url: url,
      cache: false,
      contentType: false,
      processData: false
    };
    config.data = data === undefined ? {} : data;

    switch (type) {
      case "patch":
        config.type = "post";
        config.data._method = "patch";
        break;
      case "put":
        config.type = "post";
        config.data._method = "put";
        break;
      case "delete":
        config.type = "post";
        config.data._method = "delete";
        break;
      default:
        config.type = type;
        break;
    }

    $.ajax(config)
      .done(function(response) {
        return func(response);
      })
      .fail(function(response) {
        console.log(response);
      });
  }

  /**
   * Parse Api Errors
   */
  function parseApiError(response) {
    var value;
    switch (response.status) {
      // case 400:
      // value = response.message;
      // break;
      default:
        value = response.responseJSON.message;
        break;
    }

    return value;
  }

  /**
   * Expose Properties and Methods
   */
  return {
    makeAjaxCall: makeAjaxCall,
    makeDataAjaxCall: makeDataAjaxCall,
    $token: $token
  };
})();

App.events = (function() {
	/**
	 * Stacked events
	 */
    var events = {};

	/**
	 * Suscribe to event
	 */
    function suscribe(eventName, fn) {
        events[eventName] = events[eventName] || [];
        events[eventName].push(fn);
    }

	/**
	 * Unsuscribe to event
	 */
    function unsuscribe(eventName, fn) {
        if (events[eventName]) {
            for (var i = 0; i < events[eventName].length; i++) {
                if( events[eventName][i] === fn ) {
                    events[eventName].splice(i, 1);
                    break;
                }
            }
        }
    }

	/**
	 * Fire event
	 */
    function fire(eventName, data) {
        if (events[eventName]) {
            events[eventName].forEach(function(fn) {
                fn(data);
            });
        }
    }

	/**
	 * Expose Properties and Methods
	 */
    return {
		suscribe: suscribe,
        unsuscribe: unsuscribe,
        fire: fire
    };
})();
